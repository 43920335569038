/* 全部作品 */
import React, { useState, useEffect } from 'react'
import { Input, Button, message, Select, Pagination, Spin } from 'antd';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { listCourse, courseCategoryList } from "../../http/modules/organization";
import ReviewWorks from './reviewWorks'
import './index.scss'
const { Search } = Input;

export default function Index() {

  //全部课程类别
  const [categoryList, setCategoryList] = useState([])
  // 作品数量  分页器总数
  const [number, setNumber] = useState(0)
  // 分页器  当前页数
  const [current, setCurrent] = useState(1)
  // 课程信息
  const [info, setInfo] = useState()
  // 课程类别选择
  const [categorySelect, setCategorySelect] = useState()
  // 评审状态选择
  const [auditStateSelect, setAuditStateSelect] = useState()
  // 搜索的内容
  const [searchTxt, setSearchTxt] = useState()
  // 审核作品
  const [reviewWorks, setReviewWorks] = useState(false)
  // 审核作品id
  const [reviewWorksId, setReviewWorksId] = useState(false)
  // loding
  const [loading, setLoading] = useState(false)

  // 下拉课程类别
  const handleChange = (value) => {
    setCategorySelect(value)
  };
  // 下拉评审状态
  const handleChange2 = (value) => {
    setAuditStateSelect(value)
  };

  // 分页器点击回调
  const onPagination = (page) => {
    onListCourse(page,categorySelect,auditStateSelect,searchTxt)
  }

  // 课程列表
  const onListCourse = async (page, category = '', auditState = '', searchTxt = '') => {
    setLoading(true)
    try {
      const res = await listCourse({
        "pageNo": page,
        "pageSize": 12,
        "category": category, // 课程类别
        "auditState": auditState, // 评审状态
        "searchTxt": searchTxt // 课程名称 授课人 申报单位 搜索
      });
      if (res.code == 0) {
        setCurrent(res.data.pageNo)
        setNumber(res.data.total)
        setInfo(res.data.list)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      message.error(err);
    }
  };

  //课程类别
  const onCourseCategoryList = async () => {
    const res = await courseCategoryList({})
    setCategoryList(res.data?.list)
  }

  useEffect(() => {
    onCourseCategoryList()
    onListCourse(1)
  }, [])

  return (
    <Spin spinning={loading}>
      {reviewWorks ? <ReviewWorks setReviewWorks={setReviewWorks} reviewWorksId={reviewWorksId} searchTxt={searchTxt}
        onListCourse={onListCourse} current={current} auditStateSelect={auditStateSelect} categorySelect={categorySelect} /> : <div className='allCourses'>
        <div className='filter'>
          <div className='p'>
          搜索内容：
            <Input
              placeholder="请输入课程名称"
              prefix={<SearchOutlined />}
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
              size="large"
            />
          </div>
          <div className='p'>
            课程类别：
            <Select
              value={categorySelect}
              defaultValue="全部"
              style={{ width: 300 }}
              onChange={handleChange}
              options={categoryList.map(item => ({ value: item.name, label: item.name }))}
            />
          </div>
          <div className='p'>
            评审状态：
            <Select
              value={auditStateSelect}
              defaultValue="全部"
              style={{ width: 300 }}
              onChange={handleChange2}
              options={[
                {
                  value: '已审核',
                  label: '已审核',
                },
                {
                  value: '未审核',
                  label: '未审核',
                }
              ]}
            />
          </div>
          <div className='p'>
            <Button type="primary" onClick={() => { onListCourse(1, categorySelect, auditStateSelect, searchTxt) }} shape="round" icon={<SearchOutlined />} size={'large'}>
              查询
            </Button>
            <Button onClick={() => { setCategorySelect('全部'); setAuditStateSelect('全部');setSearchTxt('');onListCourse(1,'','','') }} style={{ background: '#FFFFFF', color: '#1F78FE' }} type="primary" shape="round" icon={<UndoOutlined />} size={'large'}>
              重置
            </Button>
          </div>
        </div>
        <div className='tongJi'>
          <p>共有 <span>{number}</span> 个作品</p>
        </div>
        <main>
          {info?.map((item, index) => {
            return <div key={index}>
              <div className='divHeader'>
                <video src={item.mainCourseUrl} controls muted preload='none'></video>
              </div>
              <div className='divFooter'>
                <p title={item.title}> {item.title} </p>
                <p>
                  <span onClick={() => { setReviewWorks(true); setReviewWorksId(item.uuid) }} style={{ margin: '0 20px' }}> {item.scoreExpert != 0 || item.scoreOrg != 0 ? '已审核' : '未审核'} </span>
                </p>
              </div>
            </div>
          })}
        </main>
        <footer>
          <Pagination showSizeChanger={false} onChange={onPagination} total={number} current={current} pageSize={12} />
        </footer>
      </div>}
    </Spin>
  )
}
