/* 审核作品 */
import React, { useState, useEffect, useRef } from 'react'
import { infoCourse, scoreCourseByExpert, scoreCourseByOrg } from "../../http/modules/organization";
import { Form, Input, Button, InputNumber, message, } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import './index.scss'

export default function ReviewWorks(props) {

    let flag = localStorage.getItem('flag')
    const videoRef = useRef(null);
    const mySetInterval = useRef();

    const { reviewWorksId, setReviewWorks, onListCourse, current, categorySelect, auditStateSelect, searchTxt } = props
    // 课程详情
    const [course, setCourse] = useState()
    // 附件
    const [fujianData, setFujianData] = useState()
    // 课程分数
    const [score1, setScore1] = useState()
    // 内容分数
    const [score2, setScore2] = useState()
    // 素养分数
    const [score3, setScore3] = useState()
    // 总分数
    const [score4, setScore4] = useState()
    // 是否显示进度条
    const [isPlay, setIsPlay] = useState(false)
    // 需要观看时长
    const [times, setTimes] = useState()

    // 课程详情
    const onInfoCourse = async () => {
        try {
            const res = await infoCourse({
                "courseUUID": reviewWorksId
            });
            if (res.code == 0) {

                // 限制时间
                // res.data.typ == '短课程' ? setTimes(180) : setTimes(480)
                setTimes(0)

                setFujianData(res.data.attachmentList)
                setCourse(res.data)
            }
        } catch (err) {
            message.error(err);
        }
    };

    // 企业家打分
    const org = async () => {
        try {
            const res = await scoreCourseByOrg({
                "courseUUID": reviewWorksId,
                "scoreOrg": score4||course.scoreOrg,
                "scoreOrgContent": score1||course.scoreOrgContent,
                "scoreOrgEffect": score2||course.scoreOrgEffect,
                "scoreOrgProfession": score3||course.scoreOrgProfession
            });
            if (res.code == 0) {
                message.success('提交成功')
                onListCourse(current, categorySelect, auditStateSelect, searchTxt)
                setReviewWorks(false)
            } else {
                message.error(res.msg)
            }
        } catch (err) {
            message.error(err);
        }
    }

    // 专家打分
    const expert = async () => {
        try {
            const res = await scoreCourseByExpert({
                "courseUUID": reviewWorksId,
                "scoreExpert": score4||course.scoreExpert,
                "scoreExpertContent": score1||course.scoreExpertContent,
                "scoreExpertEffect": score2||course.scoreExpertEffect,
                "scoreExpertProfession": score3||course.scoreExpertProfession
            });
            if (res.code == 0) {
                message.success('提交成功')
                onListCourse(current, categorySelect, auditStateSelect, searchTxt)
                setReviewWorks(false)
            } else {
                message.error(res.msg)
            }
        } catch (err) {
            message.error(err);
        }
    }

    // 提交
    const onFinish = async () => {
        // let time = videoRef?.current?.currentTime
        // if (time < times) {
        //     return message.error('观看时长不够')
        // }
        if (flag == 'org') {
            org()
        } else {
            expert()
        }
    }

    //总分
    useEffect(() => {
        let a = score1 + score2 + score3
        setScore4(a)
    }, [score1, score2, score3])

    // 视频开始播放
    const play = (e) => {
        mySetInterval.current = setInterval(function () {
            let time = videoRef?.current?.currentTime
            if (time > times) {
                setIsPlay(true)
                clearInterval(mySetInterval.current)
            }
        }, 1000)
        return () => {
            clearInterval(mySetInterval.current)
        }
    }

    // 视频暂停播放
    const pause = (e) => {
        clearInterval(mySetInterval.current)
    }

    useEffect(() => {
        onInfoCourse()
        return () => {
            clearInterval(mySetInterval.current)
        }
    }, [])

    return (
        <div className='reviewWorks'>
            <header>
                <p></p>
                审核作品
            </header>
            <Form
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 18,
                }}
                layout="horizontal"
            >

                <Form.Item
                    label="授课人"
                >
                    {course ? <Input defaultValue={course?.playerName} disabled={true} /> : ''}
                </Form.Item>

                <Form.Item label="课程名称" name='title'>
                    {course ? <Input defaultValue={course?.title} disabled={true} /> : ''}
                </Form.Item>

                <Form.Item label="课程类别" name='category'>
                    {course ? <Input defaultValue={course?.title} disabled={true} /> : ''}
                </Form.Item>

                <Form.Item label="授课类型" name='typ'>
                    {
                        course ? <Input defaultValue={course?.typ} disabled={true} /> : ''
                    }
                </Form.Item>

                <Form.Item label="课程视频" valuePropName="fileList">
                    <>
                        {course ? <div className={isPlay ? 'video' : ' videos'}>
                            <video onPlay={play} onPause={pause} ref={videoRef} id='videos' src={course?.mainCourseUrl?.split("?")[0]} controls width={430} height={200} controlsList={isPlay ? '' : 'noplaybackrate'}></video>
                        </div> : ''}
                    </>
                </Form.Item>

                <Form.Item label="教辅工具" valuePropName="fileList">
                    <div className='fujian'>
                        {fujianData?.map((item) => {
                            return <p style={{ width: '100%' }}>
                                <LinkOutlined className='icon' /> <a target="_blank" href={item.url} download>{item.name}</a>
                            </p>
                        })}
                    </div>
                </Form.Item>

                <Form.Item label="初审评分" name="" valuePropName="checked">
                    <div className='daFen'> <span>课程内容：</span> <InputNumber maxlength="2" precision={0} disabled={course?.scoreExpert != 0 || course?.scoreOrg != 0 ? 'disabled' : ''}
                        parser={(text) => (/^\d+$/.test(text) ? text : text.slice(0, -1))} onChange={(e) => setScore1(e)} controls={false} value={score1}
                        addonAfter="分" min={0} max={40} placeholder={course?.scoreOrgContent || course?.scoreExpertContent ? course.scoreOrgContent || course.scoreExpertContent : '满分40,输入整数'} /></div>
                    <div className='daFen'> <span>教学效果：</span> <InputNumber maxlength="2" precision={0} disabled={course?.scoreExpert != 0 || course?.scoreOrg != 0 ? 'disabled' : ''}
                        parser={(text) => (/^\d+$/.test(text) ? text : text.slice(0, -1))} onChange={(e) => setScore2(e)} controls={false} value={score2}
                        addonAfter="分" min={0} max={30} placeholder={course?.scoreOrgEffect || course?.scoreExpertEffect ? course.scoreOrgEffect || course.scoreExpertEffect : '满分30,输入整数'} /></div>
                    <div className='daFen'> <span>教师素养：</span> <InputNumber maxlength="2" precision={0} disabled={course?.scoreExpert != 0 || course?.scoreOrg != 0 ? 'disabled' : ''}
                        parser={(text) => (/^\d+$/.test(text) ? text : text.slice(0, -1))} onChange={(e) => setScore3(e)} controls={false} value={score3}
                        addonAfter="分" min={0} max={30} placeholder={course?.scoreOrgProfession || course?.scoreExpertProfession ? course.scoreOrgProfession || course.scoreExpertProfession : '满分30,输入整数'} /></div>
                    <div className='daFen'> <span>总   分：</span> <InputNumber  precision={0} disabled='disabled' onChange={(e) => setScore4(e)} controls={false} value={score4}
                        addonAfter="分"  placeholder={course?.scoreOrg || course?.scoreExpert ? course.scoreOrg || course.scoreExpert : ''} /></div>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 4,
                        span: 6,
                    }}>
                    <Button type="primary" htmlType="submit" className='queDing' onClick={onFinish}>
                        确定
                    </Button>
                    <Button onClick={() => setReviewWorks(false)} type="primary" htmlType="submit" className='quXiao'>
                        取消
                    </Button>
                </Form.Item>

            </Form>
        </div>
    )
}
